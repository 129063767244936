.div_contenedor {
    background-color: #fff;
}

.div_general {
    border: 1px solid #337ab7;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 2%;
}

.div_general_titulo {
    background-color: #337ab7;
    border-radius: 4px;
    color: white;
    margin-bottom: 20px;
}

.general_titulo {
    margin: 0px;
}

.div_general_select {
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1%;
    height: 30px;
    width: 90%;
    font-size: 14px;
    border: 1px solid grey;
    border-radius: 5px;
}

.div_general_input {
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1%;
    height: 30px;
    width: 30%;
    margin-right: 1%;
    margin-left: 1%;
    font-size: 14px;
    border: 1px solid grey;
    border-radius: 5px;
}

.div_general_textarea {
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1%;
    height: 90px;
    width: 90%;
    margin-right: 1%;
    margin-left: 1%;
    font-size: 14px;
    border-radius: 5px;
}


.texto_1 {
    text-align: left;
    font-size: smaller;
    margin-left: 19%;
}

.textos {
    display: flex;
    justify-content: space-around;
    font-size: smaller;
    margin-right: 1%;
}

.botones {
    background-color: #337ab7;
    color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;
}

.boton_back {
    background-color: #337ab7;
    color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;
    margin-left: 75%;
}

.boton_back2 {
    background-color: #337ab7;
    color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;
    margin-right: 89%;
}

.boton_back:hover  {
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.botones:hover {
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

a:link, a:visited, a:active {
    text-decoration:none;
}