.titulo {
    background-color: #337ab7;
    color: white;
    border: 1px solid #337ab7;
    border-radius: 3px;
    font-size:medium;
}

.div {
    border: 1px solid #337ab7;
    border-radius: 5px;
    margin: 5px;
    margin-bottom: 10px;
    font-size: small;
    text-align: left;
    width: 80%;
    margin-left: 10%;
}