.table {
    width: 80%;
    margin-left: 10%;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 3%;
}

.table thead {
    background-color:aquamarine;
    text-align: center;
}

.table tbody {
    text-align: center;
}