.div_general {
    margin-top: 3%;
    margin-bottom: 5px;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
}

.div_detalles {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}

.div_comentarios {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    margin-top: 0;
}

.p_comentarios {
    margin: 0;
}

.div_datos {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    color: black;
    font-size: smaller;
    margin: 0px;
}

.div_observaciones {
    padding-left: 20px;
    text-align: left;
    color: black;
    font-size: smaller;
}

a:link, a:visited, a:active {
    text-decoration:none;
}