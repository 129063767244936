/* div input {
    width: 150%;
    height: 30px;
    border: 1px solid grey;
    border-radius: 5px;
}

div select {
    width: 100%;
    height: 30px;
    border: 1px solid grey;
    border-radius: 5px;
} */

div label {
    width: 100%;
    /* text-align: right; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.text_1 {
    margin-left: -23%;
}

.text_2 {
    margin-left: 21.5%;
}

.text_3 {
    margin-right: 78%;
}

.text_4 {
    margin-right: 75%;
}

.text_5 {
    margin-left: -24%;
}

.text_6 {
    margin-left: 27%;
}

.text_7 {
    margin-left: -15%;
}

.text_8 {
    margin-left: 30%;
}

.text_9 {
    margin-left: -26%;
}

.text_10 {
    margin-left: -26%;
}

.text_11 {
    margin-left: 28%;
    margin-right: 23%;
}

.text_12 {
    margin-left: -18%;
}

.text_13 {
    margin-left: 29%;
    margin-right: 27%;
}

.text_14 {
    margin-left: -22%;
}

.text_15 {
    margin-left: 30%;
    margin-right: 22%;
}

.text_16 {
    margin-left: -27%;
}

.text_17 {
    margin-left: 28.5%;
    margin-right: 26%;
}

.text_18 {
    margin-left: -22%;
}

.text_19 {
    margin-left: -23%;
}

.text_20 {
    margin-left: 25%;
}

.text_21 {
    margin-left: -14%;
}

.text_22 {
    margin-left: 23%;
}

.text_23 {
    margin-left: -18.5%;
}

.text_24 {
    margin-left: 17.5%;
    margin-right: 17%;
}

.text_25 {
    margin-left: -80%;
}

.text_26 {
    margin-left: -20.5%;
}

.text_27 {
    margin-left: 27.5%;
    margin-right: 24%;
}

.text_28 {
    margin-right: 74%;
}

.text_29 {
    margin-left: -28%;
}

.text_30 {
    margin-left: 22.5%;
}

.text_31 {
    margin-left: -16%;
}

.text_32 {
    margin-left: -16.5%;
}

.text_33 {
    margin-left: 29%;
    margin-right: 20%;
}

.text_34 {
    margin-left: -81%;
}

.text_35 {
    margin-left: -76%;
}


/* TEXTOS SECCION EDITAR ADMIN */

.text_36 {
    margin-left: -19%;
}

.text_37 {
    margin-left: 22.5%;
    margin-right: 30%;
}

.text_38 {
    margin-left: -28.5%;
    margin-right: 18.5%;
}

.text_39 {
    margin-left: -16%;
    margin-right: 18%;
}

.text_40 {
    margin-left: -22%;
    margin-right: 24%;
}

.text_41 {
    margin-left: -21%;
    margin-right: 23%;
}

.text_42 {
    margin-right: 73%;
}

 /* TEXTO TOTAL VENTAS */

 .p {
    font-size: smaller;
    text-align: left;
    margin-left: 40px;
 }

 .div_text {
    display: flex;
    justify-content: space-around;
    font-size: smaller;
 }