.boton_1 {
    background-color: #337ab7;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 20px;
    margin: 5px;
    width: 15%;
}

.boton_1:hover{
    background-color: white;
    color: #337ab7;
    border:1px solid #337ab7;
}

.boton_2 {
    background-color: #337ab7;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    margin-right: 83%;
}

.boton_2:hover {
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.boton_3 {
    background-color: #337ab7;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
}

.boton_3:hover {
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.boton_4 {
    background-color: white;
    border: none;
    margin-bottom: 2rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    font-size: 1rem;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
}

.boton_4:hover {
    background-color: #e71588;
    color: white;
    border: none;
}

.boton_5 {
    background-color: #337ab7;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
}

.boton_5:hover{
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.boton_6 {
    background-color: #337ab7;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    margin-right: 75.5%;

}

.boton_6:hover {
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.div_botones {
    width: 90%;
    margin-left: 5%;
    margin-top: 2rem;
    display: flex;
    justify-content:space-between;
}

.boton_7 {
    background-color: #337ab7;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
}

.boton_7:hover {
    background-color: white;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.boton_8 {
    background-color: #e71588;
    color: white;
    border: none;
    font-size: 1rem;
    padding: 8px 15px;
    border-radius: 3px;
}